import React, {useState, useEffect} from 'react'
import {Link, navigate} from 'gatsby'
import {Image, Item } from 'semantic-ui-react'
import Video from '../Video'
import Prompt from '../Prompt'
import ImageCarousel from '../Carousel/imageCarousel'
import * as styles from '../ProductSummary/styles/productSum.module.css'
import '../ProductSummary/styles/productSum.css'

export default ({
	name,
	mainImageHref,
	organizationName,
	organizationUrl,
	datestart,
	link,
	linkdetail,
	fees,
	subtitle,
	hasRegistered,
	recorddetails,
	showTeaserVideo,
	videourl
}) => {

	let startdateobj = new Date(datestart);
    let schedulestr = new Intl.DateTimeFormat('en-US', { dateStyle: 'full', timeStyle: 'short' }).format(startdateobj);

	let curdate = new Date();
	const completed = curdate.toISOString().substring(0,10) > startdateobj.toISOString().substring(0,10);
	const hasvideo = typeof videourl !== "undefined"?videourl.length > 0:false;
	const fulltitle = subtitle.length > 0?name+" "+subtitle:name;
	return (
		<React.Fragment>
			<Item.Image size="massive">
				{(hasvideo && showTeaserVideo) ?
					<Video src={videourl+"?autoplay=1&loop=1"} title={fulltitle} size={"wide"} />
				:
					<Image src={mainImageHref} alt={name} />
				}
			</Item.Image>
			<Item.Group>
				<Item className={styles.item}>
					<Item.Content>
						<Item.Header className={styles.header}><h1>{fulltitle}</h1></Item.Header>
						<Item.Description className={styles.text}>
							Presented by &nbsp;
							{organizationUrl.length > 0
							?
								<Link to={`/organization/${organizationUrl}/`} className={styles.link}>
									{organizationName}
								</Link>
							:
								<>{organizationName}</>
							}
							<table>
								<tr>
									<td>Schedule:</td>
									<td>{completed?schedulestr+" (COMPLETED)":schedulestr}</td>
								</tr>
								{(linkdetail.length > 0 && completed === false) && <tr>
									<td valign="top">Venue:</td>
									<td valign="top">
										{linkdetail.split("\n").map((venuerow, venuerowidx)=>{
											return <div>{venuerow}</div>
										})}
										{link.length > 0 &&<>
											(<a href={link} target="_blank" rel="noopener noreferrer">Map</a>)
										</>}
									</td>
								</tr>}
								{(fees.length > 0 && completed === false && hasRegistered === false) && <tr>
									<td>Fees:</td>
									<td>{fees}</td>
								</tr>}
								{(hasRegistered && completed===false && linkdetail.length < 1) && <>
									<tr>
										<td>
											{recorddetails.linktype.length > 0?
												<>{recorddetails.linktype+":"}</>
											:
												<>Details:</>
											}
										</td>
										<td>
											{recorddetails.linktype.length>0 ?
												<a rel="noopener noreferrer" href={recorddetails.linkaddress} target="_blank">{recorddetails.linkaddress}</a>
											:
												"To be announced"
											}
										</td>
									</tr>
								</>}
							</table>
						</Item.Description>
					</Item.Content>
				</Item>
			</Item.Group>

		</React.Fragment>
	)
}
